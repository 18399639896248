
import React from 'react';
import Profile from './prop/profile';
import Sum from './media/sum.jpg'
import kh from './media/kh.jpg'
import './css/team.css'


import '@fontsource/poppins';

function Team() {

  return (
    <>
      <div className='team'>
        <div className='thead'>Meet Our Founders</div>
        <div className='tbody'>
          <Profile showXLogo={true} showIlogo={true} showLnlogo={true} ImageSrc={Sum} Ilink={'https://www.instagram.com/alphab_009?igsh=MWlhNWd1cmh4MHo3dg=='} xlink={'https://twitter.com/lpha_b'} lnlink={'https://www.linkedin.com/in/suman-raj-sah/'} Name={'Suman Raj Sah'} Role={'CEO & Founder'} />
          <Profile ImageSrc={kh} showXLogo={false} showIlogo={true} showLnlogo={1} Ilink={'https://www.instagram.com/khushal.sah?igsh=MTN2MDd5amppOGlpOA=='} xlink={'#'} lnlink={'https://www.linkedin.com/in/khushal-sah-90191027b/'} Name={'Khushal Sah'} Role={'CMO & Co-Founder'} />
        </div>

      </div>
    </>



  );
}

export default Team;
