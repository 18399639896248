
import React from 'react';
import './css/sqx.css'
import '@fontsource/poppins';
import banner from './media/qslogow.png'
import sqx from './media/sqx.png'
import genixera from './media/genixera.png'
import sitrai from './media/sitrai-logo.png'
import xai from './media/0xai.png.png'
import Product1 from './prop/product1';


function Sqx() {

  return (
    <>
      <div className='sqx'>
        <h1 className='sqxhead'>ECOSYSTEM</h1>
        <div className='sqxbody'>
          <Product1 plink={'https://squarexlabs.io'} pimage={sqx} parah={'Innovation Hub'} para={'Where we Reserch, Develop and launch '} quote={'Live'} product={true} />
          <Product1 plink={'https://sitrai.com'} pimage={sitrai} parah={'SitrAi'} para={'Bridging AI and Blockchain for a Seamless Future'} quote={'Try Now'} product={true} />
          <Product1 plink={'https://genixera.com'} pimage={genixera} parah={'GeniXera'} para={'Decentralized Social Media Platform  '} quote={'Try Now'} product={true} />
          <Product1 plink={'https://0xxplorer.sitrai.com'} pimage={xai} parah={'0xXplorer AI'} para={'Ai powered blockchain explorer'} quote={'Try Now'} product={true} />
          <Product1 plink={'https://squarexlabs.io'} pimage={banner} parah={'NFT Pass'} para={'Membership pass with unlimited sake'} quote={'Coming Soon'} product={false} />
          <Product1 plink={'https://squarexlabs.io'} pimage={banner} parah={'Cryptocurrency'} para={"Qubicsquare's ecosystem run our own token"} quote={'Coming Soon'} product={false} />
          <Product1 plink={'https://squarexlabs.io'} pimage={banner} parah={'Web3 + Ai'} para={'Develop web3 + Ai based software'} quote={'Coming Soon'} product={false} />
        </div>
      </div>
    </>



  );
}

export default Sqx;
