
import React from 'react';
import './css/about.css'
import '@fontsource/poppins';
import Rect1 from './prop/rect1';
import Rect2 from './prop/rect2';
import logo from './media/qslogow.png'

function About() {

  return (
    <>
      <div className='about'>
        <div class="aimagec">
          <img src={logo} className='aimage' alt='qubicsquare' />
        </div>
        <h1 className='ahead'>
          ABOUT US
        </h1>
        <Rect1 head={'VISION'} para={'QubicSquare Technologies envisions a future where the power of Web3, artificial intelligence, and cryptocurrency converges to create unparalleled software solutions. We strive to be at the forefront of technological advancement, fostering a world where businesses operate efficiently, individuals experience enhanced digital interactions, and innovation knows no bounds. Our vision is to be a global leader, inspiring a tech-driven evolution that shapes the way we live, work, and connect in the digital age.'} />
        <Rect2 head={'MISSION'} para={'At QubicSquare Technologies, our mission is to revolutionize the digital landscape by seamlessly integrating cutting-edge technologies. We are dedicated to empowering businesses and individuals with innovative Web3 solutions, artificial intelligence, and cryptocurrency applications. Through our relentless commitment to excellence, we aim to redefine the possibilities of software development and usher in a new era of transformative experiences.'} />
      </div>
    </>



  );
}

export default About;
