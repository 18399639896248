import Home from './pages/Home'
import SM from './pages/prop/SocialMedia'
import React, { useEffect } from 'react';
import { useState } from "react";
import Preloader from './pages/prop/Loader'
import { Routes, Route } from 'react-router-dom'; import '@fontsource/rubik-mono-one';
import './App.css';


function App() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      try {
        const response = await fetch('https://jsonplaceholder.typicode.com/data');
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }, 1000); // set timeout to 5 seconds

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      {loading || !data ? (
        <Preloader />
      ) : (
        <div className="App">
          <Routes>
            <Route path="/" element={<Home data={data} />} />
          </Routes>
          <div><SM /></div>

        </div>
      )}
    </>
  );
}


export default App;
