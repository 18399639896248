
import React from 'react';
import '../css/Line.css'
import int from '../media/qslogowh.png'

import "@fontsource/michroma";

function Line() {

  return (
    <div className="Line">
      <div class="fline"></div>
      <div class="footerc" >
        <div className='rect'></div>
        <img class="flogo" src={int} alt="QUBICSQUARE" />

      </div>
    </div>
  );
}

export default Line;
