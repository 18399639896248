import React from 'react';
//import RingLoader from "react-spinners/RingLoader";
import int from '../media/qslogowh.png'


import '@fontsource/poppins';
import "../css/preloader.css"

function Preloader() {

  return (
    <div className="bodyl">
      <div className='logo-cont'>
        <div className='rect'></div>
        <img className="logol" src={int} alt="qubicsquare" />
      </div>

      <h2 class="h2l">Empowering Your New Era Experience</h2>

    </div>
  );
}

export default Preloader;
