
import React from 'react';
import '../css/Footer.css'
import "@fontsource/michroma";
import flogo from '../media/full.png'


function Footer() {

  return (
    <div className="Footer">
      <div className='fupper'>
        <div class='fleft'>
          <div class="footerlogo" ><img class="ftlogo" src={flogo} alt="qubicsquare" /></div>
          <br />
          <div class='tagline'>
            Contact us on <a href='mailto:contact@qubicsquare.tech'>contact@qubicsquare.tech</a>
          </div><br />
          <div className='flink'>
            <a href='https://qubicsquare.tech'>Term and conditions</a>
            <a href='https://qubicsquare.tech'>Privacy Policy</a>
          </div>
        </div>

      </div>
      <div className='flower'>
        <hr style={{ height: '0.1px', width: '85%', background: 'white', textAlign: 'center' }} />
        <div className='copyr'>Created By SquareX Lab with &#128150; </div>
        <br />
        <div className='copyr'>COPYRIGHTS &copy;{new Date().getFullYear()}&nbsp;, QUBICSQUARE TECHNOLOGIES Pvt. Ltd. All Rights Reserved</div>
      </div>

    </div>
  );
}

export default Footer;
